import { AoLibService, TaskViewerService } from '@anderson-optimization/ui';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AoRun } from '@anderson-optimization/ao-lib';
import { BehaviorSubject } from 'rxjs';

type RefreshOptions = {
  useViewer?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    public aoLib: AoLibService,
    protected http: HttpClient,
    protected taskViewerService: TaskViewerService,
  ) {}

  get$(requestId) {
    return this.http.get(`${environment.gridDetect.url}/search/request/${requestId}`);
  }

  list$() {
    return this.http.get(`${environment.gridDetect.url}/search/request`);
  }

  async refresh(request, refreshOptions: RefreshOptions = {}) {
    const { useViewer = true } = refreshOptions;
    const taskRn = environment.gridDetect.searchTaskRn;
    const options = {
      event: {
        task: {
          cid: request.company,
          tid: request.team,
          regionName: request.region_id,
        },
        drawing: {
          wkt: request.wkt,
        },
      },
      aoRun: {},
    };
    const aoRun: AoRun = await this.aoLib.service.task.runTask(taskRn, options);
    console.log('AoRun', aoRun);
    if (useViewer) {
      const runViewer = {
        type: 'taskRun',
        name: aoRun.run.rn,
        id: aoRun.run.rn,
        aoRuns: new BehaviorSubject(aoRun),
      };
      this.taskViewerService.add(runViewer);
    }
    return aoRun.finished;
  }

  recalc(request, type) {
    console.log('Recalculating line layer');
    return this.http.post(`${environment.gridDetect.url}/tile/${type}/recalc`, {
      wkt: request.wkt,
    });
  }
  classify(itemType, request) {
    console.log(`Classifing ${itemType}`);
    return this.http.post(`${environment.gridDetect.url}/${itemType}/class`, {
      wkt: request.wkt,
      wkb: request.geom,
    });
  }
  count(itemType, request) {
    console.log(`Counting ${itemType}`);
    return this.http.post(`${environment.gridDetect.url}/${itemType}/count`, {
      wkt: request.wkt,
      wkb: request.geom,
    });
  }
  countGet(itemType, request) {
    console.log(`Counting ${itemType}`);
    let params = new HttpParams();
    params = params.append('wkb', request.geom);
    return this.http.get(`${environment.gridDetect.url}/${itemType}/count`, {
      params,
    });
  }

  // tileId = sub/pole
  queue$(tileId, data) {
    console.log(`Queue:${tileId}`, data);
    return this.http.post(`${environment.gridDetect.url}/tile/${tileId}/queue`, {
      ...data,
    });
  }
}
