import { AMOMap } from '@anderson-optimization/ui/lib/b0.map/classes/amo-map.factory';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environment';
import { random } from 'lodash';

import { addFeature, flash } from './layer';

import { sparcle } from './explode';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  selector: 'dg-image-holder',
  templateUrl: './image-holder.component.html',
  styleUrls: ['./image-holder.component.scss'],
})
export class ImageHolderComponent implements OnInit {
  @Input() url: string;
  @Input() map: AMOMap;
  @ViewChild('element') ele: ElementRef;
  @Input() data: any;

  x: number;
  y: number;

  opacity = 1;

  minX = -50;
  maxX = 1200;

  minY = -200;
  maxY = 1000;

  deltaYMax = 20;
  ts = 25;

  destroyed = false;

  constructor(protected http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    this.x = random(this.minX, this.maxX);
    this.y = this.minY;

    await delay(500);
    const root = document.getElementById('app-root');
    root.appendChild(this.ele.nativeElement);
    this.animate();

    console.log('HAVE MAP', this.map);

    this.http
      .get(`${environment.gridDetect.url}/pole/${this.data.id}`)
      .subscribe((p) => this.handlePole(p));
  }

  handlePole(pole) {
    console.log('Handle Pole', pole);
    const { wkt } = pole;

    const feature = addFeature(wkt);
    flash(feature, this.map.ol.map);
  }

  async animate() {
    const delta = random(this.deltaYMax);
    this.y += delta;
    if (this.destroyed && this.opacity > 0) {
      this.opacity = this.opacity - 0.05;
    }

    await delay(this.ts);

    if (this.y < this.maxY) {
      this.animate();
    }
  }

  destroyMe(event) {
    console.log('DESTROY ME', event);
    this.destroyed = true;
    sparcle(event);
  }
}
