import { Component, Input, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { FormService } from '../form.service';
import { RequestService } from '../requests/request.service';
import { searchSchema } from './search-schema';

@Component({
  selector: 'dg-search-actions',
  templateUrl: './search-actions.component.html',
  styleUrls: ['./search-actions.component.scss'],
})
export class SearchActionsComponent implements OnInit {
  @Input()
  geom: string;

  @Input()
  request: any;

  constructor(
    protected form: FormService,
    protected svc: RequestService,
  ) {}

  ngOnInit(): void {}

  async refresh() {
    console.log('Runtask', this.request);
    await this.svc.refresh(this.request);
    console.log('Finished Refreshing!');
  }

  recalcLines() {
    console.log('Recalc', this.request);
    this.svc.recalc(this.request, 'line').subscribe((v) => {
      console.log('Recalculating lines!');
    });
  }
  recalcPriors() {
    console.log('Recalc', this.request);
    this.svc.recalc(this.request, 'prior').subscribe((v) => {
      console.log('Recalculating lines!');
    });
  }

  // tileId=sub/pole
  initSearch(tileId) {
    const dialogRef = this.form.openForm({
      schema: searchSchema,
      data: {
        geom: this.geom,
        detector: 'v1',
        source: 'vexcel',
        minProb: 0.1,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(switchMap((output) => this.svc.queue$(tileId, output)))
      .subscribe((resp) => {
        console.log('Response!', resp);
      });
  }

  initClassify(itemType) {
    this.svc.classify(itemType, this.request).subscribe((v) => {
      console.log('Classifying lines!');
    });
  }
}
