import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';
import { AoLibService } from '@anderson-optimization/ui';

@Component({
  selector: 'dg-tiles-search',
  templateUrl: './tile-search.component.html',
  styleUrls: ['./tile-search.component.scss'],
})
export class TileSearchComponent implements OnInit {
  constructor(
    protected http: HttpClient,
    protected aoLib: AoLibService,
  ) {
    this.aoLib.$team.setTid('demo-echo');
  }

  count$: Observable<any>;

  ngOnInit(): void {
    this.count$ = this.http
      .get(`${environment.gridDetect.url}/tile/detail/count`)
      .pipe(shareReplay(1));
  }
}
