import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-pole-detect',
  templateUrl: './pole-detect.component.html',
  styleUrls: ['./pole-detect.component.scss'],
})
export class PoleDetectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
