import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TileService {
  constructor(protected http: HttpClient) {}

  rate$(tile, interval) {
    return this.http.get(
      `${environment.gridDetect.url}/tile/${tile}/rate?interval=${interval}`,
    );
  }
}
