<!-- <p>stream-consumer works!</p> -->
<mat-card class="status-card">
  <mat-card-header class="card-header">
    <mat-card-title>
      Stream
      <mat-spinner
        [diameter]="20"
        color="accent"
        class="refresh-spinner"
        *ngIf="updating"
      ></mat-spinner>
    </mat-card-title>
    <mat-card-subtitle>{{ stream }}/{{ consumer }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="consumer$ | async as consumerInfo">
    <mat-list role="list">
      <mat-list-item role="listitem">
        <span class="key"> channel: </span>
        <b>{{ (stream$ | async)?.config.subjects.join(',') }}</b></mat-list-item
      >
      <mat-list-item role="listitem">
        <span class="key"> messages: </span>
        <b>{{ (stream$ | async)?.state.messages }}</b></mat-list-item
      >
      <mat-list-item role="listitem" *ngFor="let key of consumerKeys">
        <span class="key"> {{ key }}: </span>
        <b>{{ consumerInfo[key] }}</b>
        <span *ngIf="consumerPair$ | async as pair" class="diff">
          <span *ngIf="diff(pair, key) > 0">{{ diff(pair, key) }}</span>
          <span *ngIf="diff(pair, key) < 0">{{ diff(pair, key) }}</span>
        </span>
      </mat-list-item>
    </mat-list>
    <mat-card-actions>
      <button mat-button color="warn" (click)="purge()">Purge</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
