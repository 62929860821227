<div class="page">
  <label id="example-radio-group-label">Create type</label>
  <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [ngModel]="type"
    (change)="onChange($event)"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let type of types"
      [value]="type"
    >
      {{ type }}
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="element$ | async as element">
    <h2>Create Request</h2>
    <ao-form
      [schema]="schema"
      [data]="data"
      [layout]="layout"
      (onChanges)="onUpdate($event)"
    >
    </ao-form>
  </div>
</div>
