import { Component, OnInit, Input, Output } from '@angular/core';
import { Observable, interval } from 'rxjs';

import {
  map,
  switchMap,
  tap,
  delay,
  startWith,
  shareReplay,
  pairwise,
} from 'rxjs/operators';
import { pick, omit } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { LogService } from '@anderson-optimization/ui';
import { environment } from 'src/environments/environment';
import EventEmitter from 'events';
import { TileService } from '../tile.service';

@Component({
  selector: 'dg-tile-rate',
  templateUrl: './tile-rate.component.html',
  styleUrls: ['./tile-rate.component.scss'],
})
export class TileRateComponent implements OnInit {
  @Input() tile: string;
  @Input() interval: string;
  @Input() updateInterval: number = 10;

  constructor(protected tileService: TileService) {}

  rate$: Observable<any>;
  updating: boolean = false;

  ngOnInit(): void {
    this.rate$ = interval(this.updateInterval * 1000).pipe(
      startWith(0),
      tap(() => (this.updating = true)),
      switchMap(() => this.tileService.rate$(this.tile, this.interval)),
      shareReplay(1),
      delay(500),
      tap(() => (this.updating = false)),
    );
  }
}
