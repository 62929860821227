import { Map } from 'ol';
import { Circle as CircleStyle, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';

import WKT from 'ol/format/WKT';

const format = new WKT();

const source = new VectorSource({
  wrapX: false,
});
const vector = new VectorLayer({
  source: source,
});

export function addFeature(wkt) {
  console.log('ADdFeature', wkt);
  const feature = format.readFeature(wkt, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });
  source.addFeature(feature);
  return feature;
}

const duration = 3000;
export function flash(feature, map: Map) {
  // ensure sourceLayer is on map

  const layers = map.getLayers();
  if (layers.getLength() < 1) {
    map.addLayer(vector);
  }
  console.log('HAVE LAYERS', map, map.getLayers(), source);

  // find base layer
  let tileLayer = vector;

  const start = Date.now();
  const flashGeom = feature.getGeometry().clone();
  // lets find baselayer from map
  const listenerKey = tileLayer.on('postrender', animate);

  function animate(event) {
    //   console.log('flash',event);
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * 30 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, ' + opacity + ')',
          width: 0.25 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    map.render();
  }
  map.render();
}
