<div class="page">
  <h2>Deployment</h2>
  <div class="sliders">
    <mat-card class="example-result-card">
      <mat-card-content>
        <h2>
          Services
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="updatingDeployment"
          ></mat-spinner>
        </h2>
        <div class="services" *ngIf="deployments$ | async as deployments">
          <div *ngFor="let service of services">
            <div class="example-label-container example-label-vertical">
              <label id="example-name-label" class="example-name-label">{{
                service.name
              }}</label>
              <label class="example-value-label"
                >{{ getDeploymentReplicas(service, deployments) }}/{{
                  service.desiredReplicas
                }}</label
              >
            </div>
            <mat-slider
              class="example-margin large-slider"
              [max]="service.maxReplicas"
              [min]="service.minReplicas"
              [step]="1"
              [thumbLabel]="true"
              [tickInterval]="5"
              [ngModel]="service.desiredReplicas"
              [vertical]="true"
              (change)="triggerScale(service, $event)"
              aria-labelledby="example-name-label"
            >
            </mat-slider>
            <!-- <div class="example-label-container example-label-vertical">
                            <label id="example-name-label" class="example-name-label">{{service.name}}</label>
                            <label class="example-value-label">{{getDeploymentReplicas(service,deployments)}}/{{service.desiredReplicas}}</label>
                        </div> -->
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
