import { AoLibService } from '@anderson-optimization/ui';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'demo';

  isSignedIn$: Observable<boolean>;
  photoUrl$: Observable<string>;
  tid$: Observable<string>;
  name$: Observable<string>;
  email$: Observable<string>;
  env: string;

  options = {
    bottom: 0,
    fixed: false,
    top: 0,
  };

  links = [
    {
      href: 'overview',
      name: 'Overview',
      icon: 'map',
    },
    {
      href: 'requests',
      name: 'Requests - List',
      icon: 'list',
    },
    {
      href: 'requests/create',
      name: 'Requests - Create',
      icon: 'create',
    },
    {
      href: 'pole/classifier',
      name: 'Pole - Classifier',
      icon: 'image_search',
    },
    {
      href: 'pole/detect',
      name: 'Pole - Detect',
      icon: 'image_search',
    },
    {
      href: 'sub/classifier',
      name: 'Sub - Classifier',
      icon: 'image_search',
    },
    {
      href: 'sub/detect',
      name: 'Sub - Detect',
      icon: 'image_search',
    },
    {
      href: 'tile/prior',
      name: 'Tile - Prior',
      icon: 'calculate',
    },
    {
      href: 'tile/overlay',
      name: 'Tile - Overlay',
      icon: 'mediation',
    },
    {
      href: 'deployment',
      name: 'Deployment',
      icon: 'computer',
    },
  ];

  // <a routerLink="requests">
  //   requests
  // </a>
  // <br />

  // <a routerLink="tile/search">
  //   tile/search
  // </a>
  // <br />
  // <a routerLink="tile/prior">
  //   tile/prior
  // </a>
  // <br />

  // <a routerLink="form">
  //   form
  // </a>
  // <br />

  // <a routerLink="map">
  //   map
  // </a>

  constructor(protected aoLib: AoLibService) {
    this.isSignedIn$ = this.aoLib.authUser$.pipe(
      map((authUser) => (authUser ? true : false)),
    );
    this.photoUrl$ = this.aoLib.authUser$.pipe(
      map((authUser) => (authUser ? authUser.photoURL : undefined)),
      filter((v) => (v ? true : false)),
    );
    this.tid$ = this.aoLib.tid$;
    this.name$ = this.aoLib.user$.pipe(
      map((user: any) => user?.name),
      filter((v) => (v ? true : false)),
    );
    this.email$ = this.aoLib.user$.pipe(
      map((user: any) => {
        console.log('user', user);
        return user?.email;
      }),
      filter((v) => (v ? true : false)),
    );
    this.env = environment.gridDetect.env;
  }

  ngOnInit() {
    this.aoLib.uid$.subscribe((uid) => {
      console.log('HAVE UID!', uid);
    });
    this.aoLib.tid$.subscribe((tid) => {
      console.log('HAVE TID!', tid, this.aoLib.$team.getTid());
    });
  }
}
