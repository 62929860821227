<div class="page">
  <h2>Sub Detector</h2>
  <p>
    This is a tile processing grid for detecting electrical infrastructure
    (pole/substation).
  </p>
  <div class="card-display">
    <dg-tile-count tile="sub" updateInterval="10000"> </dg-tile-count>
    <dg-stream-consumer stream="tile" consumer="sub-detector"></dg-stream-consumer>
  </div>
</div>
