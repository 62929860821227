import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FormComponent } from './form/form.component';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(public dialog: MatDialog) {}

  openForm(data) {
    return this.dialog.open(FormComponent, {
      data,
    });
  }
}
