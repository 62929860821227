import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, authStates } from '@anderson-optimization/ui';
import { DeploymentComponent } from './deployment/deployment.component';
import { FormComponent } from './form/form.component';
import { OverviewComponent } from './overview/overview.component';
import { PoleClassifierComponent } from './pole-classifier/pole-classifier.component';
import { PoleDetectComponent } from './pole-detect/pole-detect.component';
import { RequestCreateComponent } from './requests/request-create/request-create.component';
import { RequestDetailComponent } from './requests/request-detail/request-detail.component';
import { RequestViewComponent } from './requests/request-view/request-view.component';
import { RequestsComponent } from './requests/requests-list/requests.component';
import { SubClassifierComponent } from './sub-classifier/sub-classifier.component';
import { SubDetectComponent } from './sub-detect/sub-detect.component';
import { TileOverlayComponent } from './tile-overlay/tile-overlay.component';
import { PriorComponent } from './tile-prior/tile-prior.component';
import { TileSearchComponent } from './tile-search/tile-search.component';

const routes: Routes = [
  ...authStates,
  {
    path: 'requests',
    canActivate: [AuthGuard],
    component: RequestViewComponent,
    data: {
      requiresLogin: true,
    },
    children: [
      {
        path: '',
        component: RequestsComponent,
      },
      {
        path: 'create',
        canActivate: [AuthGuard],
        component: RequestCreateComponent,
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        component: RequestDetailComponent,
      },
    ],
  },
  {
    path: 'overview',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'deployment',
    canActivate: [AuthGuard],
    component: DeploymentComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'pole/classifier',
    canActivate: [AuthGuard],
    component: PoleClassifierComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'pole/detect',
    canActivate: [AuthGuard],
    component: PoleDetectComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'sub/classifier',
    canActivate: [AuthGuard],
    component: SubClassifierComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'sub/detect',
    canActivate: [AuthGuard],
    component: SubDetectComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'tile/prior',
    canActivate: [AuthGuard],
    component: PriorComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'tile/search',
    canActivate: [AuthGuard],
    component: TileSearchComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'tile/overlay',
    canActivate: [AuthGuard],
    component: TileOverlayComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: 'form',
    canActivate: [AuthGuard],
    component: FormComponent,
    data: {
      requiresLogin: true,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
