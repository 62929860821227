import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.scss'],
})
export class RequestViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
