export const searchSchema = {
  type: 'object',
  properties: {
    geom: {
      type: 'string',
    },
    source: {
      type: 'string',
      enum: ['google', 'vexcel'],
      default: 'vexcel',
    },
    detector: {
      type: 'string',
      enum: ['v1'],
      default: 'v1',
    },
    minProb: {
      type: 'number',
      default: 0.1,
    },
  },
  required: ['wkt', 'source', 'detector'],
};
