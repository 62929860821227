<div *ngIf="!data">Loading...</div>
<div *ngIf="data">
  <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
  </mat-form-field>

  <button mat-button color="accent" (click)="refreshAll()">
    <mat-icon> refresh </mat-icon>
    Refresh All ({{ dataSource.filteredData.length }})
  </button>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="requested_ts"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let element" class="column">
        <a [routerLink]="'./' + element.id">{{ element.id }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td mat-cell *matCellDef="let element" class="column">{{ element.company }}</td>
    </ng-container>
    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
      <td mat-cell *matCellDef="let element" class="column">{{ element.team }}</td>
    </ng-container>
    <ng-container matColumnDef="region_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Region Id</th>
      <td mat-cell *matCellDef="let element" class="column">{{ element.region_id }}</td>
    </ng-container>
    <ng-container matColumnDef="requested_ts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested</th>
      <td mat-cell *matCellDef="let element" class="column">
        {{ element.requested_ts | date }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
