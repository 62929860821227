<div id="mode-display"></div>
<div id="app-root">
  <mat-toolbar class="header">
    <img src="assets/img/logo.png" class="brand-icon" />
    Grid Detect ({{ env }})

    <button type="button" mat-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="header-spacer"></span>
    <div class="auth">
      <img class="avatar" [src]="photoUrl$ | async" />
      <span [matTooltip]="email$ | async">
        {{ name$ | async }}
      </span>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="auth menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/auth/logout" *ngIf="isSignedIn$ | async">
          <mat-icon aria-hidden="false" aria-label="lgout">logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <a routerLink="/auth/login" *ngIf="!(isSignedIn$ | async)">
        <mat-icon aria-hidden="false" aria-label="login">Login</mat-icon>
      </a>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="container">
    <mat-sidenav
      #sidenav
      mode="side"
      opened
      class="sidenav"
      [fixedInViewport]="options.fixed"
      [fixedTopGap]="options.top"
      [fixedBottomGap]="options.bottom"
    >
      <mat-nav-list>
        <a
          mat-list-item
          color="accent"
          [routerLink]="link.href"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngFor="let link of links"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="{{ link.href }}"
            *ngIf="link.icon"
            >{{ link.icon }}</mat-icon
          >

          {{ link.name }}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <task-run-viewer></task-run-viewer>
</div>
