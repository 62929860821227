import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AoLibService } from '@anderson-optimization/ui';

import { map, switchMap, tap, delay, startWith } from 'rxjs/operators';
import { pick, omit } from 'lodash';

@Component({
  selector: 'dg-prior',
  templateUrl: './tile-prior.component.html',
  styleUrls: ['./tile-prior.component.scss'],
})
export class PriorComponent implements OnInit {
  constructor(
    protected http: HttpClient,
    public aoLib: AoLibService,
  ) {
    this.tid$ = this.aoLib.tid$;
    this.tid$.subscribe(() => {
      this.aoLib.$team.setTid('demo-echo');
    });
  }

  tid$: Observable<string>;
  count$: Observable<any>;
  stream$: Observable<any>;

  updatingStream: boolean = false;
  updatingCount: boolean = false;

  mapOptions = {
    activeLayer: 'prior_tiles_v3',
  };

  ngOnInit(): void {
    this.count$ = interval(2000).pipe(
      startWith(0),
      tap(() => (this.updatingCount = true)),
      switchMap(() => this.http.get(`${environment.gridDetect.url}/tile/prior/count`)),
      delay(500),
      tap(() => (this.updatingCount = false)),
    );

    this.stream$ = interval(2000).pipe(
      startWith(0),
      tap(() => (this.updatingStream = true)),
      switchMap(() =>
        this.http.get(
          `${environment.gridDetect.url}/process/stream/prior/consumers/prior-create`,
        ),
      ),
      map((v) =>
        pick(v, ['num_ack_pending', 'num_redelivered', 'num_waiting', 'num_pending']),
      ),
      delay(500),
      tap(() => (this.updatingStream = false)),
    );
  }

  refresh() {
    this.http
      .get(`${environment.gridDetect.url}/tile/prior/refresh`)
      .subscribe((resp) => {
        console.log('Refreshed!', resp);

        this.count$ = this.http.get(`${environment.gridDetect.url}/tile/prior/count`);
      });
  }
}
