import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AoLibService, LogService } from '@anderson-optimization/ui';

import { map, switchMap, tap, delay, startWith } from 'rxjs/operators';
import { pick, omit } from 'lodash';

@Component({
  selector: 'dg-tile-overlay',
  templateUrl: './tile-overlay.component.html',
  styleUrls: ['./tile-overlay.component.scss'],
})
export class TileOverlayComponent implements OnInit {
  tid$: Observable<string>;

  constructor(
    protected http: HttpClient,
    public aoLib: AoLibService,
    protected log: LogService,
  ) {
    this.tid$ = this.aoLib.tid$;
    this.tid$.subscribe(() => {
      this.aoLib.$team.setTid('demo-echo');
    });
  }

  count$: Observable<any>;

  updatingCount: boolean = false;

  mapOptions = {
    activeLayer: 'line_tiles_v3',
  };

  ngOnInit(): void {}
}
