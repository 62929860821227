import { AoLibService } from '@anderson-optimization/ui';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  constructor(public aoLib: AoLibService) {
    this.aoLib.$team.setTid('demo-echo');
  }

  ngOnInit(): void {}
}
