import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-pole-classifier',
  templateUrl: './pole-classifier.component.html',
  styleUrls: ['./pole-classifier.component.scss'],
})
export class PoleClassifierComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
