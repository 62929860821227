<div *ngIf="request$ | async as request">
  <h2 class="header">
    {{ request.company }} / {{ request.team }} - {{ request.region_id }}

    <small [style.margin-left]="'10px'"> {{ request.requested_ts | date }} </small>
    <div class="push-right"></div>
    <button *ngIf="!immerseMode" mat-button color="accent" (click)="immerse()">
      <mat-icon> add_a_photo_alternate </mat-icon>
      Immerse
    </button>
    <button
      *ngIf="allowDestroyMode && !destroyMode"
      mat-button
      color="warn"
      (click)="loadExplosions()"
    >
      <mat-icon> broken_image </mat-icon>
      Destroy Mode
    </button>
    <button mat-button color="accent" [cdkCopyToClipboard]="request.wkt">
      <mat-icon> file_copy </mat-icon>
      WKT
    </button>
    <button mat-button color="accent" [cdkCopyToClipboard]="request.geom">
      <mat-icon> file_copy </mat-icon>
      WKB
    </button>
    <dg-search-actions [geom]="request.geom" [request]="request"></dg-search-actions>
  </h2>

  <div class="card-display">
    <!-- <mat-card class="status-card">
            <mat-card-header class="card-header">
                <mat-card-title>
                    Search Pole
                    <mat-spinner [diameter]="20" color="accent" class="refresh-spinner"
                        *ngIf="!(countSearchPole$ | async)"></mat-spinner>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="(countSearchPole$ | async) as count">
                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <span class="key">
                            tiles:
                        </span> <b>{{count.total}}</b>
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <span class="key">
                            done:
                        </span> <b>{{count.done}}</b>
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <span class="key">
                            todo:
                        </span> <b>{{count.toCalc}}</b>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card> -->
    <mat-card class="status-card">
      <mat-card-header class="card-header">
        <mat-card-title>
          Poles
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="!(countPole$ | async)"
          >
          </mat-spinner>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="countPole$ | async as count">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <span class="key"> found: </span> <b>{{ count.total }}</b>
            <span *ngIf="polePair$ | async as pair" class="diff">
              <span *ngIf="diff(pair, 'total') > 0" class="green"
                >+{{ diff(pair, 'total') }}</span
              >
              <span *ngIf="diff(pair, 'total') < 0" class="red">{{
                diff(pair, 'total')
              }}</span>
            </span>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> classified: </span> <b>{{ count.classified }}</b>
            <span *ngIf="polePair$ | async as pair" class="diff">
              <span *ngIf="diff(pair, 'classified') > 0" class="green"
                >+{{ diff(pair, 'classified') }}</span
              >
              <span *ngIf="diff(pair, 'classified') < 0">{{
                diff(pair, 'classified')
              }}</span>
            </span>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> todo: </span> <b>{{ count.toClassify }}</b>
            <span *ngIf="polePair$ | async as pair" class="diff">
              <span *ngIf="diff(pair, 'toClassify') > 0">{{
                diff(pair, 'toClassify')
              }}</span>
              <span *ngIf="diff(pair, 'toClassify') < 0">{{
                diff(pair, 'toClassify')
              }}</span>
            </span>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <mat-card class="status-card">
      <mat-card-header class="card-header">
        <mat-card-title>
          Lines
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="!(countLine$ | async)"
          >
          </mat-spinner>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="countLine$ | async as count">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <span class="key"> found: </span> <b>{{ count.total }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> classified: </span> <b>{{ count.classified }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> todo: </span> <b>{{ count.toClassify }}</b>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <!-- <mat-card class="status-card">
            <mat-card-header class="card-header">
                <mat-card-title>
                    Search Sub
                    <mat-spinner [diameter]="20" color="accent" class="refresh-spinner"
                        *ngIf="!(countSearchSub$ | async)"></mat-spinner>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="(countSearchSub$ | async) as count">
                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <span class="key">
                            tiles:
                        </span> <b>{{count.total}}</b>
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <span class="key">
                            done:
                        </span> <b>{{count.done}}</b>
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <span class="key">
                            todo:
                        </span> <b>{{count.toCalc}}</b>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card> -->
    <mat-card class="status-card">
      <mat-card-header class="card-header">
        <mat-card-title>
          Substations
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="!(countSub$ | async)"
          >
          </mat-spinner>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="countSub$ | async as count">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <span class="key"> found: </span> <b>{{ count.total }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> classified: </span> <b>{{ count.classified }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> todo: </span> <b>{{ count.toClassify }}</b>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <mat-card class="status-card">
      <mat-card-header class="card-header">
        <mat-card-title>
          Tile Prior
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="!(countTilePrior$ | async)"
          ></mat-spinner>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="countTilePrior$ | async as count">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <span class="key"> tiles: </span> <b>{{ count.total }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> done: </span> <b>{{ count.done }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> todo: </span> <b>{{ count.toCalc }}</b>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <mat-card class="status-card">
      <mat-card-header class="card-header">
        <mat-card-title>
          Tile Overlay
          <mat-spinner
            [diameter]="20"
            color="accent"
            class="refresh-spinner"
            *ngIf="!(countTileLine$ | async)"
          ></mat-spinner>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="countTileLine$ | async as count">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <span class="key"> tiles: </span> <b>{{ count.total }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> done: </span> <b>{{ count.done }}</b>
          </mat-list-item>
          <mat-list-item role="listitem">
            <span class="key"> todo: </span> <b>{{ count.toCalc }}</b>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngFor="let c of classified">
    <dg-image-holder
      *ngIf="map$ | async"
      [url]="c.cropped_url"
      [data]="c"
      [map]="map$ | async"
    >
    </dg-image-holder>
  </div>
</div>
