import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { MatTableDataSource } from '@angular/material/table';
import { RequestService } from '../request.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface RequestItem {
  id: string;
  company: string;
  team: string;
  region_id: string;
  requested_ts: number;
}

@Component({
  selector: 'dg-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements AfterViewInit, OnInit {
  constructor(protected requestService: RequestService) {}

  requests$: Observable<any>;
  columns = ['id', 'company', 'team', 'region_id', 'requested_ts'];
  displayedColumns = this.columns;

  dataSource: MatTableDataSource<RequestItem> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: RequestItem[];

  ngOnInit(): void {
    this.requestService.list$().subscribe((requests: RequestItem[]) => {
      this.dataSource.data = requests;
      this.data = requests;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log('HaveSort', this.sort);
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async refreshAll() {
    console.log('RefreshAll');
    for await (let request of this.dataSource.filteredData) {
      console.log('request', request);
      await this.requestService.refresh(request);
      console.log('Finished Refreshing!');
    }
  }
}
