import { AoLibService, LogService } from '@anderson-optimization/ui';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { environment } from 'src/environments/environment';

import { find } from 'lodash';
import {
  debounceTime,
  delay,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'dg-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
})
export class DeploymentComponent implements OnInit {
  constructor(
    protected http: HttpClient,
    public aoLib: AoLibService,
    protected log: LogService,
  ) {}

  services = [
    {
      name: 'Prior Calc',
      key: 'prior-calc',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 100,
    },
    {
      name: 'Pole Detect',
      key: 'pole-detect',
      gpu: true,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 8,
    },
    {
      name: 'Sub Detect',
      key: 'sub-detect',
      gpu: true,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 8,
    },
    {
      name: 'Pole Classify',
      key: 'pole-classify',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 50,
    },
    {
      name: 'Sub Classify',
      key: 'sub-classify',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 50,
    },
    {
      name: 'Overlay Lines',
      key: 'overlay-line',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 100,
    },
    {
      name: 'Search',
      key: 'search',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 1,
    },
    {
      name: 'API',
      key: 'api',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 50,
    },
    {
      name: 'NATS',
      key: 'nats',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 50,
    },
    {
      name: 'Imagery',
      key: 'imagery',
      gpu: false,
      desiredReplicas: undefined,
      minReplicas: 0,
      maxReplicas: 100,
    },
  ];

  scale$: Subject<any> = new Subject();
  deployments$: Observable<any>;
  updatingDeployment = false;

  ngOnInit(): void {
    this.deployments$ = interval(3000).pipe(
      startWith(0),
      tap(() => (this.updatingDeployment = true)),
      switchMap(() => this.http.get(`${environment.gridDetect.url}/deployment`)),
      shareReplay(1),
      delay(500),
      tap(() => (this.updatingDeployment = false)),
    );

    this.deployments$.pipe(take(1)).subscribe((deployments) => {
      console.log('first', deployments);
      this.services.forEach((service) => {
        service.desiredReplicas = this.getDeploymentReplicas(service, deployments);
      });
    });

    this.scale$
      .pipe(
        debounceTime(1500),
        switchMap((v) => {
          console.log('Scaling Service', v);
          const { service, replicas } = v;
          return this.http.put(
            `${environment.gridDetect.url}/deployment/${service.key}`,
            {
              replicas,
            },
          );
        }),
      )
      .subscribe((v) => {
        console.log('Scaled Service!', v);
      });
  }

  getDeployment(service, deployments) {
    const d = find(deployments.items, (deployment) => {
      // console.log(deployment.metadata.name,service.key);
      return deployment.metadata.name === service.key;
    });
    // console.log(d);
    return d;
  }
  getDeploymentReplicas(service, deployments) {
    const deployment = this.getDeployment(service, deployments);
    if (!deployment) {
      return 0;
    }

    return deployment.status.replicas || 0;
  }

  triggerScale(service, event) {
    service.desiredReplicas = event.value;
    this.scale$.next({
      service,
      replicas: event.value,
    });
  }
}
