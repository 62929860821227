import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  AoFormModule,
  AoLibModule,
  AppMaterialModule,
  AuthModule,
  LogModule,
  TaskModule,
} from '@anderson-optimization/ui';
import { FormComponent } from './form/form.component';

import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DeploymentComponent } from './deployment/deployment.component';
import { ImageHolderComponent } from './image-holder/image-holder.component';
import { OverviewComponent } from './overview/overview.component';
import { PoleClassifierComponent } from './pole-classifier/pole-classifier.component';
import { PoleDetectComponent } from './pole-detect/pole-detect.component';
import { RequestCreateComponent } from './requests/request-create/request-create.component';
import { RequestDetailComponent } from './requests/request-detail/request-detail.component';
import { RequestViewComponent } from './requests/request-view/request-view.component';
import { RequestsComponent } from './requests/requests-list/requests.component';
import { SearchActionsComponent } from './search-actions/search-actions.component';
import { StreamConsumerComponent } from './stream-consumer/stream-consumer.component';
import { SubClassifierComponent } from './sub-classifier/sub-classifier.component';
import { SubDetectComponent } from './sub-detect/sub-detect.component';
import { TileCountComponent } from './tile-count/tile-count.component';
import { TileOverlayComponent } from './tile-overlay/tile-overlay.component';
import { PriorComponent } from './tile-prior/tile-prior.component';
import { TileRateComponent } from './tile-rate/tile-rate.component';
import { TileSearchComponent } from './tile-search/tile-search.component';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    PriorComponent,
    RequestsComponent,
    TileSearchComponent,
    TileOverlayComponent,
    RequestDetailComponent,
    RequestCreateComponent,
    OverviewComponent,
    RequestViewComponent,
    DeploymentComponent,
    StreamConsumerComponent,
    TileCountComponent,
    PoleClassifierComponent,
    TileRateComponent,
    PoleDetectComponent,
    SubDetectComponent,
    SubClassifierComponent,
    SearchActionsComponent,
    ImageHolderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AoLibModule,
    AoFormModule,
    TaskModule,
    AuthModule,
    LogModule,
    AppMaterialModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
