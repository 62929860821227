<mat-card class="status-card">
  <mat-card-header>
    <!-- <div mat-card-avatar class="example-header-image"></div> -->
    <mat-card-title
      >Tile Counts
      <mat-spinner
        [diameter]="20"
        color="accent"
        class="refresh-spinner"
        *ngIf="updating"
      ></mat-spinner>
    </mat-card-title>
    <mat-card-subtitle>{{ tile }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="count$ | async as count">
      <h5>To Calc</h5>
      <p>
        {{ count.toCalc }} / {{ count.total }} =
        {{ (count.toCalc / count.total) * 100 | number: '1.0-1' }}%

        <span *ngIf="countPair$ | async as pair" class="diff">
          <span *ngIf="diff(pair, 'toCalc') > 0">{{ diff(pair, 'toCalc') }}</span>
          <span *ngIf="diff(pair, 'toCalc') < 0">{{ diff(pair, 'toCalc') }}</span>
        </span>
      </p>
      <h5>Done</h5>
      <p>
        {{ count.done }} / {{ count.total }} =<b
          >{{ (count.done / count.total) * 100 | number: '1.0-1' }}%</b
        >
      </p>
      <h5 *ngIf="count.stale">Stale</h5>
      <p *ngIf="count.stale">
        {{ count.stale }} / {{ count.total }} =
        {{ (count.stale / count.total) * 100 | number: '1.0-1' }}%
      </p>
    </div>
    <dg-tile-rate interval="1 minute" [tile]="tile"></dg-tile-rate>
    <dg-tile-rate interval="1 hour" [tile]="tile"></dg-tile-rate>
    <dg-tile-rate interval="1 day" [tile]="tile"></dg-tile-rate>
    <mat-card-actions>
      <button mat-button color="accent" (click)="refresh()">Refresh Stale</button>
      <button mat-button color="warn" (click)="recalc()">Recalc All</button>
      <button
        mat-button
        color="warn"
        (click)="clear()"
        matTooltip="Clear tiles that have not been requested from the queue"
      >
        Clear
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
