<div class="page">
  <h2>Pole Classifier</h2>
  <p>This manages the classification of detect poles by voltage level</p>
  <div class="card-display">
    <dg-stream-consumer
      updateInterval="5"
      stream="pole"
      consumer="pole-classifier"
    ></dg-stream-consumer>
  </div>
</div>
