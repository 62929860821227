<div class="page">
  <h2>Prior Tiles</h2>
  <p>
    This is a tile processing grid for calculating probabilities of individual tiles to
    contain electrical infrastructure.
  </p>
  <div class="card-display">
    <dg-tile-count tile="prior"> </dg-tile-count>
    <dg-stream-consumer stream="prior" consumer="prior-create"></dg-stream-consumer>
  </div>
</div>
