import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface FormData {
  schema: any;
  data: any;
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: FormData) {}

  ngOnInit(): void {}

  onUpdate(event) {
    console.log('OnUpdate!', event);
    this.data.data = event;
  }
  // handle(event){
  //   console.log('have new event',event);
  //   this.data = {...event}
  // }
}
