import { Component, OnInit, Input, Output } from '@angular/core';
import { Observable, interval } from 'rxjs';

import {
  map,
  mergeMap,
  tap,
  delay,
  startWith,
  shareReplay,
  pairwise,
} from 'rxjs/operators';
import { pick, omit } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { LogService } from '@anderson-optimization/ui';
import { environment } from 'src/environments/environment';
import EventEmitter from 'events';

@Component({
  selector: 'dg-tile-count',
  templateUrl: './tile-count.component.html',
  styleUrls: ['./tile-count.component.scss'],
})
export class TileCountComponent implements OnInit {
  @Input() tile: string;
  @Input() updateInterval: number = 10;
  @Input() staleInterval: string = '5 minute';
  @Output() onRefresh: EventEmitter = new EventEmitter();

  constructor(
    protected http: HttpClient,
    protected log: LogService,
  ) {}

  count$: Observable<any>;
  countPair$: Observable<any>;
  rate$: Observable<any>;
  updating: boolean = false;

  ngOnInit(): void {
    this.count$ = interval(this.updateInterval * 1000).pipe(
      startWith(0),
      tap(() => (this.updating = true)),
      mergeMap(() =>
        this.http.get(
          `${environment.gridDetect.url}/tile/${this.tile}/count?interval=${this.staleInterval}`,
        ),
      ),
      shareReplay(1),
      delay(500),
      tap(() => (this.updating = false)),
    );
    this.countPair$ = this.count$.pipe(pairwise());
  }
  refresh() {
    this.http
      .get(
        `${environment.gridDetect.url}/tile/${this.tile}/refresh?interval=${this.staleInterval}`,
      )
      .subscribe((resp) => {
        console.log('Refreshed!', resp);
      });
  }
  recalc() {
    this.http
      .get(`${environment.gridDetect.url}/tile/${this.tile}/recalc`)
      .subscribe((resp) => {
        console.log('recalc!', resp);
        this.log.info('Recalculating!');
      });
  }
  clear() {
    this.http
      .get(`${environment.gridDetect.url}/tile/${this.tile}/clear`)
      .subscribe((resp) => {
        console.log('clear!', resp);
        this.log.info('Cleared!');
      });
  }

  diff(pair, key) {
    return pair[1][key] - pair[0][key];
  }
}
