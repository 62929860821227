import { AoLibService, LogService } from '@anderson-optimization/ui';
import { AMOElement } from '@anderson-optimization/ui/lib/b0.map/classes/amo-element.factory';
// import { TaskRunnerService } from '@anderson-optimization/ui/lib/b0.task/group.task-run/task-runner.service';
// import { AoTask, AoTaskFactory } from '@anderson-optimization/ui/lib/b0.task/group.task-run/task-runner/ao-task-runner.factory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dg-request-create',
  templateUrl: './request-create.component.html',
  styleUrls: ['./request-create.component.scss'],
})
export class RequestCreateComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public aoLib: AoLibService,
    protected log: LogService,
    // protected trService: TaskRunnerService, protected aoTaskFactory: AoTaskFactory
  ) {}

  types = ['utility', 'state', 'county'];
  type: string;
  mapOptions$: BehaviorSubject<any> = new BehaviorSubject(null);
  element$: Subject<AMOElement> = new Subject();

  layerNames = {
    utility: 'gd_region_utility',
    state: 'gd_region_state',
    county: 'gd_region_county',
  };

  schema = {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        name: 'Name',
      },
      tid: {
        type: 'string',
        name: 'Team ID',
      },
      cid: {
        type: 'string',
        name: 'Company ID',
      },
      wkt: {
        type: 'string',
        name: 'WKT',
      },
    },
  };
  layout = [
    'name',
    {
      key: 'tid',
      type: 'teamSelect',
    },
    'cid',
    'wkt',
  ];
  data = {
    name: '',
    tid: '',
    cid: '',
    wkt: '',
  };

  // aoTask: AoTask;

  ngOnInit(): void {
    const taskDef = this.aoLib.resource.task.get(environment.gridDetect.searchTaskRn);
    // this.aoTask = this.aoTaskFactory.create(taskDef,[]);
    this.route.queryParams.subscribe((params) => {
      this.type = params.type ? params.type : 'county';
      this.mapOptions$.next({
        activeLayer: this.layerNames[this.type],
      });
    });

    this.element$.subscribe((e) => {
      console.log('Have element!', e);
      this.log.info('Have element for request!');
      this.data.name = e.name;
      this.data.wkt = e.wkt.toString();
    });
  }

  onUpdate(event) {
    console.log('OnUpdate', event);
  }

  onChange(event) {
    console.log('Event', event);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { type: event.value },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
  catchAmoMap(amoMap) {
    console.log('AmoMap', amoMap);
    amoMap.drawingCreated$.subscribe((v) => {
      console.log('DrawingCreated', v);
    });
    amoMap.googleLeftClick$.subscribe((v) => {
      if (v.element) {
        this.element$.next(v.element);
      }
    });
  }
}
