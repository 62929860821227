<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Example icon-button with a menu"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="initSearch('sub')">
    <mat-icon>dialpad</mat-icon>
    <span>Sub Search</span>
  </button>
  <button mat-menu-item (click)="initSearch('pole')">
    <mat-icon>voicemail</mat-icon>
    <span>Pole Search</span>
  </button>
  <button mat-menu-item (click)="initClassify('pole')">
    <mat-icon>notifications_off</mat-icon>
    <span>Pole Classify</span>
  </button>
  <button mat-menu-item (click)="initClassify('substation')">
    <mat-icon>notifications_off</mat-icon>
    <span>Sub Classify</span>
  </button>

  <button mat-menu-item (click)="refresh()" class="warn-text">
    <mat-icon color="warn"> refresh </mat-icon>
    Refresh
  </button>
  <button mat-menu-item (click)="recalcPriors()" class="warn-text">
    <mat-icon color="warn"> refresh </mat-icon>
    <span color="warn"></span>
    Recalculate Priors
  </button>
  <button mat-menu-item (click)="recalcLines()" class="warn-text">
    <mat-icon color="warn"> refresh </mat-icon>
    <span color="warn"></span>
    Recalculate Lines
  </button>
</mat-menu>
